import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tasks"
export default class extends Controller {
  static targets = ['completedTrue', 'completedFalse', 'uncompletedTrue', 'uncompletedFalse']

  toggleCompleted(event) {
    this.completedTrueTarget.disabled = this.uncompletedTrueTarget.checked
    this.completedFalseTarget.disabled = this.uncompletedTrueTarget.checked
  }
  
  toggleUncompleted(event) {
    this.uncompletedTrueTarget.disabled = this.completedTrueTarget.checked
    this.uncompletedFalseTarget.disabled = this.completedTrueTarget.checked
  }
}
