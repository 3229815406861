import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).multiselect({
      buttonWidth: '250px',
      buttonClass: 'btn dropdown-toggle btn-outline-secondary',
      maxHeight: 600
    })
  }
}
