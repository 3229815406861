import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'eventErrors', 'allDayFlag', 'dayModeButton', 'hourModeButton', 'startAtPicker', 'endAtPicker', 'startAtPickerInput', 'endAtPickerInput' ]

  connect() {
    if (this.allDayFlagTarget.value == 'true') {
      this.setDayMode()
    } else {
      this.setHourMode()
    }
  }

  setDayMode() {
    this.allDayFlagTarget.value = true

    this.hourModeButtonTarget.classList.remove('active')
    this.dayModeButtonTarget.classList.add('active')
    
    this.startAtPickerTarget.querySelector('.ficon').classList.remove('fa-clock')
    this.startAtPickerTarget.querySelector('.ficon').classList.add('fa-calendar')

    this.endAtPickerTarget.querySelector('.ficon').classList.remove('fa-clock')
    this.endAtPickerTarget.querySelector('.ficon').classList.add('fa-calendar')
    
    let currentStartAt, currentEndAt
    
    if (this.startAtPickerInputTarget.value) {
      currentStartAt = moment.utc(this.startAtPickerInputTarget.value)
    } else {
      currentStartAt = moment(new Date())      
    }
    
    if (this.endAtPickerInputTarget.value) {
      currentEndAt = moment.utc(this.endAtPickerInputTarget.value)
    } else {
      currentEndAt = moment(new Date())
    }

    $(this.startAtPickerTarget).datetimepicker('destroy')
    $(this.startAtPickerTarget).datetimepicker( { format: 'MM/DD/YYYY',
						  date: currentStartAt } )
    
    $(this.endAtPickerTarget).datetimepicker('destroy')
    $(this.endAtPickerTarget).datetimepicker( { format: 'MM/DD/YYYY',
					       date: currentStartAt} )

    $(this.startAtPickerTarget).on("change.datetimepicker", (e) => {
      $(this.endAtPickerTarget).datetimepicker('minDate', e.date)
    })
  }

  setHourMode() {
    this.allDayFlagTarget.value = false

    this.hourModeButtonTarget.classList.add('active')
    this.dayModeButtonTarget.classList.remove('active')
    
    this.startAtPickerTarget.querySelector('.ficon').classList.add('fa-clock')
    this.startAtPickerTarget.querySelector('.ficon').classList.remove('fa-calendar')

    this.endAtPickerTarget.querySelector('.ficon').classList.add('fa-clock')
    this.endAtPickerTarget.querySelector('.ficon').classList.remove('fa-calendar')

    let currentStartAt, currentEndAt
    console.log(this.startAtPickerInputTarget.value)
    console.log(this.endAtPickerInputTarget.value)
    if (this.startAtPickerInputTarget.value) {
      if ( this.startAtPickerInputTarget.value.match(/^\d{2}\/\d{2}\/\d{4}$/) ) {
	console.log('date only')
	currentStartAt = moment
	  .utc(this.startAtPickerInputTarget.value)
	  .set( 'hour', moment().hour() )
	  .set( 'minute', moment().minute() )
	
      } else {      
	currentStartAt = moment.utc(this.startAtPickerInputTarget.value)
      }
    } else {
      currentStartAt = moment(new Date())      
    }
    console.log(currentStartAt)
    
    if (this.endAtPickerInputTarget.value) {
      if ( this.endAtPickerInputTarget.value.match(/^\d{2}\/\d{2}\/\d{4}$/) ) {
	currentEndAt = moment
	  .utc(this.startAtPickerInputTarget.value)
	  .set( 'hour', moment().hour() + 1)
	  .set( 'minute', moment().minute() )
	
      } else {      
	currentEndAt = moment.utc(this.endAtPickerInputTarget.value)
      }
    } else {
      currentEndAt = moment(new Date())
    }
    
    $(this.startAtPickerTarget).datetimepicker('destroy')
    $(this.startAtPickerTarget).datetimepicker( {
      stepping: 15,
      sideBySide: true,
      format: 'MM/DD/YYYY hh:mm A',
      keepInvalid: false,
      date: currentStartAt } )
    
    $(this.endAtPickerTarget).datetimepicker('destroy')
    $(this.endAtPickerTarget).datetimepicker( {
      stepping: 15,
      sideBySide: true,
      format: 'MM/DD/YYYY hh:mm A',
      keepInvalid: false,
      date: currentEndAt } )

    $(this.endAtPickerTarget).datetimepicker('date').set( { hour: 9,
							    minute: 0 } )
    
    $(this.startAtPickerTarget).on("change.datetimepicker", (e) => {
      $(this.endAtPickerTarget).datetimepicker('minDate', e.date)
    })
  }
  
  onEventError(event) {
    let [data, status, xhr] = event.detail
    this.eventerrorsTarget.innerHTML = xhr.response
  }

  refreshEvent(event) {
    let [data, status, xhr] = event.detail
    
    $(event.srcElement).closest('.event-container').html(xhr.response)
  }
}
