import { Controller } from "@hotwired/stimulus"
const _ = require("lodash")

export default class extends Controller {
  connect() {
    let that = this;

    that.element.addEventListener('change', _.debounce(that.handleChange, 500))
  }
  
  handleChange(event) {
    event.preventDefault()

    event.target.form.requestSubmit()
  }
}
