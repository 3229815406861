import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fullcalendar", "newEventModal", "newEventForm", "newEventErrorsBlock", "crewList", "schedulingFilterForm", 'crewLeaderFrame', 'eventsListFrame']
  
  connect() {
    if (this.hasFullcalendarTarget) {
      this.initializeFullCalendar()
    }

    this.makeCrewEventsSortable()
  }
  
  initializeFullCalendar() {
    $('#calendar').fullCalendar({
      defaultDate: gon.date,
      themeSystem: 'bootstrap4',
      defaultView: gon.defaultView,
      navLinks: true,
      navLinkDayClick(date, jsEvent) {
	return window.location.href = `/calendar/daily_schedule/${date.format()}`
      },
      
      header: {
	left: 'prev,next today',
	center: 'title',
	right: 'month,basicWeek,agendaDay,basicDay'
      },
      
      eventRender(event, element, view) {
	if (event.project_type_name) {
          element.addClass(`${event.project_type_name}_slot`).addClass("project-cell");
	}
	const title = element.find( '.fc-title' );
	title.html( title.text() )
	
	return element
      },
      
      events(start, end, timezone, callback) {
	const data = {
          start: start.unix(),
          end: end.unix(),
          filter_form: $('#filter-form').serialize()
	}
	
	$.post({
          url: '/calendar/get_calendar_events',
          dataType: 'json',
          data,
          success(doc) {
            return callback(doc)
          }
	})
      }
    })
  }

  showNewEventModel(event) {
    event.preventDefault()
    $(this.newEventModalTarget).modal()
  }

  refreshEvents(event) {
    if (event.detail.success) {
      $('#calendar').fullCalendar('refetchEvents')
    }
  }
  
  setFilterDefaults(event) {
    $.post({
      url: '/calendar/month_set_defaults',
      data: {
        filter_form: $('#filter-form').serialize()
      }    })
    
    event.preventDefault()
  }

  applyCalendarFilters(event) {
    $('#calendar').fullCalendar('refetchEvents')
    event.preventDefault()
  }

  // Scheduling
  scrollEvents(event) {
    event.preventDefault()

    this.scrollToEvent(event.srcElement.dataset.eventId)
  }

  scrollToEvent(event_id) {    
    $('body').scrollTo('#title',
		       10,
		       { axis: 'y',
			 offset: { top: -80 } })

    document.getElementById(`event-crew-link-${event_id}`).scrollIntoView()

    document.getElementById(`event-block-${event_id}`).scrollIntoView()
  }
  
  makeCrewEventsSortable() {
    for (let target of this.crewListTargets) {
      this.makeCrewListSortable(target)
    }
  }
  
  makeCrewListSortable(target) {
    $(target).sortable({
      handle: $(".event-handle"),
      items: 'li',
      axis: 'y',
      containment: 'parent',
      update(e, ui) {	
	let taskable_calendar_event_id = ui.item.data('taskable-calendar-event-id');
	let position = ui.item.index()

	$.ajax({
          type: 'PATCH',
          data: { taskable_calendar_event: { position } },
	  url: `/taskable_calendar_events/${taskable_calendar_event_id}/update_position`,
          success: (data) => {
            ui.item.effect('highlight', {color:"#b3fcb4"}, 1500)
          }
	})
      }
    })
  }

  enableAllStaffLeaderOptions(event) {
    event.preventDefault()

    let container = $(event.srcElement).closest('.event-scheduling-block')

    container.find('.crew-leader').addClass('d-none')
    container.find('.crew-leader #taskable_calendar_event_crew_leader_id').attr('disabled', true)
    container.find('.crew-leader-all-staff #taskable_calendar_event_crew_leader_id').attr('disabled', false)
    container.find('.crew-leader-all-staff').removeClass('d-none')
  }

  disableAllStaffLeaderOptions(event) {
    event.preventDefault()

    let container = $(event.srcElement).closest('.event-scheduling-block')

    container.find('.crew-leader #taskable_calendar_event_crew_leader_id').attr('disabled', false)
    container.find('.crew-leader').removeClass('d-none')
    container.find('.crew-leader-all-staff #taskable_calendar_event_crew_leader_id').attr('disabled', true)
    container.find('.crew-leader-all-staff').addClass('d-none')
  }

  chooseAllStaffCrewOptions(event) {
    event.preventDefault()

    let container = $(event.srcElement).closest('.event-scheduling-block')

    let all_staff_select = container.find('.all-staff-select')
    let crew_select = container.find(".crew-select")
    
    all_staff_select.multiselect('enable')
    
    container.find('.crew-members-all-staff').show()
    
    container.find('.all-staff-link').hide()
    container.find('.crew-only-link').show()
    
    container.find('.crew-members').hide()
    crew_select.multiselect('disable')
  }

  chooseFieldCrewOptions(event) {
    event.preventDefault()

    let container = $(event.srcElement).closest('.event-scheduling-block')

    let all_staff_select = container.find('.all-staff-select')
    let crew_select = container.find(".crew-select")
    
    crew_select.multiselect('enable')
    
    container.find('.crew-members').show()
    
    container.find('.all-staff-link').show()
    container.find('.crew-only-link').hide()
    
    container.find('.crew-members-all-staff').hide()
    all_staff_select.multiselect('disable')
  }

  submitCrewForm(event) {
    event.preventDefault()
    
    event.srcElement.closest('form').requestSubmit()
  }
  
  eventsUpdating(event) {
    this.activateWaitingModal(event)
  }

  async eventsUpdated(event) {
    if (event.detail.success) {
      this.deactivateWaitingModal(event)

      /* REVISIT
	 Wait for DOM changes to settle out before scrolling.
	 Just waiting on the loaded events for the frames involved
	 doesn't appear to be satisfactory. */	 
      await this.crewLeaderFrameTarget.loaded
      await this.eventsListFrameTarget.loaded
      await new Promise(r => setTimeout(r, 100))
      
      $("#events-list").highlight_element()
      
      this.scrollToEvent(event.srcElement.dataset.eventId)

      this.makeCrewEventsSortable()
    }
  }

  activateWaitingModal(event) {
    $('#waiting-message').modal()
  }

  deactivateWaitingModal(event) {
    $('#waiting-message').modal('hide')
  }

  prepareSchedulingFilterParams(event) {
    event.detail[1].data += '&' + $(this.schedulingFilterFormTarget).serialize()
  }
}
				
