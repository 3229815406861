import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

import "tablesorter/dist/js/jquery.tablesorter"

$.fn.extend({
  highlight_element(highlightColor, duration) {
    const highlightBg = highlightColor || "#FFFF9C"
    const animateMs = duration || 1500
    const originalBg = this.css("backgroundColor")
    return this.stop().css("background-color", highlightBg).animate({backgroundColor: originalBg}, animateMs)
  },

  flash_message(message, duration) {
    this.show()
    this.html(message)            // Show the message
    this.delay(duration || 2000)  // Wait
    return this.fadeOut('fast', () => {       // Fade out
      return this.html('')
    })
  }
})     
