import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.makeEventsSortable()
  }

  editSchedulingNote(e) {
    $(e.srcElement).closest('.event-card').find('.scheduling-note').prop('contenteditable', true)
  }

  updateSchedulingNote(e) {
    let block = $(e.srcElement).closest('.event-card').find('.note-block')
    let note = block.find('.scheduling-note')
    
    block.find('#taskable_calendar_event_scheduling_note').val(note.html())
    
    Rails.fire(block.find('.note-form').get(0), 'submit')
    
    note.prop('contenteditable', false)
    
    note.effect('highlight', {}, 1000)
  }
  
  makeEventsSortable() {
    $("#events-list").sortable({
      handle: $(".event-handle"),
      items: $(".event-container"),
      axis: 'y',
      // highlight the row on drop to indicate an update
      stop(e, ui) {
	return ui.item.effect('highlight', {}, 1000);
      },
      update(e, ui) {
	let taskable_calendar_event_id = ui.item.data('taskable-calendar-event-id');
	let position = ui.item.index();
	
	return $.ajax({
	  type: 'post',
	  data: { taskable_calendar_event: { position: position } },
	  dataType: 'json',
	  complete(request) {
	    return ui.item.effect('highlight', {}, 1000);
	  },
	  url: `/taskable_calendar_events/${taskable_calendar_event_id}/update_position`
	});
      }
    });
  }
}
