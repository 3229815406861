import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {
  claim_slot(event) {
    event.preventDefault()

    let slot_id = event.target.dataset.slotId

    let taskable_id = $(event.target)
      .closest('.taskable_type_row')
      .find('input:checked')
      .val()
    
    $(`#slot_dialog_${slot_id}`).dialog('open')
    
    // Find the form and set the taskable_id
    // hidden field to match the selected taskable.
    let form = $(`#claim_slot_form_${slot_id}`)
    form.find("#taskable_id").val(taskable_id)

    if (taskable_id) {
      $.ajax(
	{
	  type: 'post',
	  url: `/dashboard/claim_slot/${taskable_id}/${slot_id}`
	}
      )
    }
  }
}
