import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show()
    
    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    this.modal.hide()
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close() {
    // Remove the modal element so it doesn't blanket the screen
    this.element.remove()
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.modal.hide()

      this.close()
    }
  }

  handleSubmit = (e) => {
    this.modal.hide()

    this.close()
  }
}
