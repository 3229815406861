import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize_map() {
    let geocoder = new (google.maps.Geocoder);

    address = gpn.address;

    let myOptions = {
      zoom: 15,
      mapTypeControl: false,
      zoomControlOptions: { style: google.maps.ZoomControlStyle.DEFAULT },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    
    geocoder.geocode({ 'address': address }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
	let map = new (google.maps.Map)(document.getElementById('map_canvas'), myOptions);
	map.setCenter(results[0].geometry.location);

	let marker = new (google.maps.Marker)({
          map,
          position: results[0].geometry.location,
          title: address});
      } else {
	alert('Geocode was not successful for the following reason: ' + status);
      }
    })
  }
}
