import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--job-types"
export default class extends Controller {
  static targets = ['newTaskNameField', 'jobTypesTable']

  connect() {
    for (let target of this.jobTypesTableTargets) {
      $(target).find('tbody').sortable({
	items: 'tr',
	// highlight the row on drop to indicate an update
	stop(e, ui) {
	  return ui.item.effect('highlight', {}, 1000);
	},
	update(e, ui) {
	  const job_type_id = ui.item.data('job-type-id');
	  const position = ui.item.index();
	  
	  return $.ajax({
            type: 'post',
            data: { job_type: { position } },
            dataType: 'json',
            complete(request) {
              return $('.jobList').effect('highlight');
            },
            url: `/admin/job_types/${job_type_id}/update_position`
	  });
	}
      });
    }
  }

  clearNewTaskName(event) {
    if (event.detail.success) {
      this.newTaskNameFieldTarget.value = ''
    }
  }

}


