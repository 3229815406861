import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal } from "bootstrap"
import tooltip from "bootstrap"

export default class extends Controller {
  static targets = ['calendar', 'popup', 'slotInfoLinksCheckbox', 'showDescriptionsCheckbox', 'showHeldSlotsCheckbox', 'showMyHeldSlotsCheckbox', 'showClaimedSlotsCheckbox', 'slotEditingModeCheckbox', 'hourSlider', 'hourValue', 'filterByCheckbox', 'taskableTypeCheckbox']

  connect() {    
    if (this.hasHourSliderTarget) {
      $(this.hourSliderTarget).slider(
	{ min: 1,
	  max: 9,
	  value: 1,
	  range: 'max',
	  slide: ( event, ui ) => {
	    this.hourValueTarget.value = ui.value
	  }
	} )
      
      this.hourValueTarget.value = $( this.hourSliderTarget).slider( "value" )
    }
    
    if (this.hasCalendarTarget) {
      let controller = this
            
      this.calendar = new Calendar(this.calendarTarget, {
	displayEventTime: true,
	themeSystem: 'bootstrap4',
	headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
	initialView: 'dayGridWeek',
	plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
	selectable: true,
	timeZone: 'UTC',

	eventClassNames: function(arg) {
	  if (arg.event.extendedProps.held_by_name) {
            return `${arg.event.extendedProps.project_type_name}_held_stripe`
          } else if (arg.event.extendedProps.claimed_by_name) {
            return `${arg.event.extendedProps.project_type_name}_claimed_stripe`
          } else {
            return `${arg.event.extendedProps.project_type_name}_slot`
          }
	},
	
	eventContent(args) {
	  let currentEvent = args.event
	  
	  let element = document.createElement('a')

	  if (controller.slotInfoLinksCheckboxTarget.checked) {
            element.setAttribute('href', `/calendar_event_slots/${currentEvent.id}` )
	    element.setAttribute('target', '_blank');
	  }
	  
	  element.innerHTML = `${args.timeText} ${currentEvent.title}`

	  if ( (args.view.type === 'dayGridDay') ||
	       controller.showDescriptionsCheckboxTarget.checked ) {
	    let descriptionSpan = document.createElement('span')
	    descriptionSpan.setAttribute('class', 'description')
	    descriptionSpan.innerHTML = `<div class="mt-0 pt-0">${controller.getEventDescription(currentEvent.extendedProps)}</div>`

	    element.append(descriptionSpan)
	  }

	  // Add destroy buttons when in Slot Editing Modle
          if ( controller.slotEditingMode() ) {
            if (! currentEvent.extendedProps.held_by_name &&
		! currentEvent.extendedProps.claimed_by_name) {
	      let destroyForm = document.createElement('form')
	      destroyForm.setAttribute('action', `/calendar_event_slots/${currentEvent.id}/remove`)
	      destroyForm.setAttribute('method', 'post')
	      destroyForm.setAttribute('style', 'display: inline')
	      destroyForm.setAttribute('class', 'close')
	      destroyForm.dataset.remote = false
	      destroyForm.dataset.turbo = true
	      destroyForm.dataset.action = 'turbo:submit-end->calendar-event-slots#refreshEvents'

	      let destroyLink = document.createElement('a')
	      destroyLink.setAttribute('href', '#')
	      destroyLink.setAttribute('onclick', 'event.preventDefault(); parentNode.requestSubmit();')
	      destroyLink.innerHTML = '&times;'

	      destroyForm.append(destroyLink)
	      
              element.append(destroyForm) 
            }
	  }
	  
	  let arrayOfDomNodes = [ element ]
	  return { domNodes: arrayOfDomNodes }
        },
	
	events(fetchInfo, successCallback, failureCallback) {
	  let data = {
            start: fetchInfo.start.toISOString().split('T')[0],
            end: fetchInfo.end.toISOString().split('T')[0],
            filter_form: $('#filter-form').serialize()
	  }
	  
	  $.get({
            url: `/calendar_event_slots/get_available_slots.json?start=${fetchInfo.start.toISOString().split('T')[0]}&end=${fetchInfo.end.toISOString().split('T')[0]}&${$('#filter-form').serialize()}`,
            dataType: 'json',
            success(doc) {
              return successCallback(doc)
            }
	  })
	},

	eventClick: function(info) {
	  controller.clearTooltip()

	  if ( controller.slotInfoMode() ) return
	  
	  if (! controller.slotEditingMode() &&
	      (info.event.extendedProps.claimed_by_name || info.event.extendedProps.held_by_name
	       && ! (info.event.extendedProps.held_by_id == gon.current_user_id) ) ) {
	    let content = `<div class="white-link">${controller.getEventDescription(info.event.extendedProps)}</div>`
	    
	    $(info.el).tooltip({html: true, title: content, trigger: 'manual'})
	    $(info.el).tooltip('show')
	    
	    controller.activeTooltip = info.el
	  } else {
	    this.token = document.querySelector(
	      'meta[name="csrf-token"]'
	    ).content
	    
	    fetch(`/calendar_event_slots/${info.event.id}/handle_slot_click`, {
	      method: 'POST',
	      headers: {
		'X-CSRF-Token': this.token,
		'Content-Type': 'application/json',
		Accept: "text/vnd.turbo-stream.html",
	      },
	      credentials: 'same-origin',
	      body: JSON.stringify({
		slot_editing_mode: controller.slotEditingMode()
	      })
	    })
	      .then(r => r.text())
	      .then(html => Turbo.renderStreamMessage(html))
	  }
	},
				   
	dateClick: function(info) {
	  if ( controller.slotEditingMode() ) {
	    this.token = document.querySelector(
	      'meta[name="csrf-token"]'
	    ).content
	    
	    fetch(`/calendar_event_slots/new?date=${info.date.toISOString().split('T')[0]}`, {
	      method: 'GET',
	      headers: {
		'X-CSRF-Token': this.token,
		'Content-Type': 'text/vnd.turbo-stream.html',
		Accept: "text/vnd.turbo-stream.html",
	      }
	    })
	      .then(r => r.text())
	      .then(html => Turbo.renderStreamMessage(html))
          } else {
            controller.clearTooltip()
	    
            this.changeView('dayGridDay')
            this.gotoDate(info.date)
	  }
	},

	loading: function(args) {
	  controller.clearTooltip()
	}
      })
      
      window.addEventListener('turbo:load', () => {
	this.calendar.render()
      })
    }
  }

  getEventDescription(data) {
    let description = '<div>'
    
    if (data.held_by_name) {
      description += `<div>Held by ${data.held_by_name}`

      if (data.hold_note)
	description += `<br>${data.hold_note}`

      description += '</div>'
    }
    
    if (data.claimed_by_name) {
      description = `<div><a href="/addresses/${data.address_id}">${data.street1}, ${data.city}</a><br>Claimed by ${data.claimed_by_name}</div>`
    }
    
    if (data.claim_note)
      description += `<div>${data.claim_note}</div>`

    description += '</div>'

    return description
  }

  toggleHoldButtons(event) {
    if (this.showMyHeldSlotsCheckboxTarget.checked) {
      this.showHeldSlotsCheckboxTarget.checked = true
      this.showHeldSlotsCheckboxTarget.disabled = true

      this.showClaimedSlotsCheckboxTarget.checked = false
      this.showClaimedSlotsCheckboxTarget.disabled = true
    } else {
      this.showHeldSlotsCheckboxTarget.disabled = false

      this.showClaimedSlotsCheckboxTarget.disabled = false
    }
  }

  toggleSlotEditingMode(event) {
    event.preventDefault
    
    if ( this.slotEditingMode() ) {
      this.showMyHeldSlotsCheckboxTarget.checked = false
      this.showMyHeldSlotsCheckboxTarget.disabled = true

      this.showClaimedSlotsCheckboxTarget.checked = true
      this.showClaimedSlotsCheckboxTarget.disabled = false
    } else {
      this.showMyHeldSlotsCheckboxTarget.disabled = false
      this.showClaimedSlotsCheckboxTarget.disabled = false
    }

    this.refreshEvents(event)
  }

  toggleGroupedFilter(event) {
    if (this.filterByCheckboxTarget.checked) {
      this.showClaimedSlotsCheckboxTarget.checked = false
      this.showHeldSlotsCheckboxTarget.checked = false

      this.showClaimedSlotsCheckboxTarget.disabled = true
      this.showHeldSlotsCheckboxTarget.disabled = true
    } else {
      this.showClaimedSlotsCheckboxTarget.disabled = false
      this.showHeldSlotsCheckboxTarget.disabled = false
    }
  }

  toggleTaskableTypes(event) {
    event.preventDefault()
    
    let any_checked = false

    for (let target of this.taskableTypeCheckboxTargets) {
      if (target.checked) {
        any_checked = true
	
	break
      }
    }

    for (let target of this.taskableTypeCheckboxTargets) {
      target.checked = ! any_checked
    }
  }

  // Clear previously set tooltip
  clearTooltip() {
    if (this.activeTooltip) {
      $(this.activeTooltip).tooltip('hide')

      this.activeTooltip = null
    }
  }

  refreshEvents(event) {
    event.preventDefault()

    this.clearTooltip()
    
    this.calendar.refetchEvents()

    this.calendar.render()
  }

  setDefaults(event) {
    event.preventDefault()

    $.post({
      url: '/calendar_event_slots/set_find_availabilty_defaults',
      data: $('#filter-form').serialize(),
      dataType: 'json'
    })
  }

  slotEditingMode() {
    return this.hasSlotEditingModeCheckboxTarget &&
      this.slotEditingModeCheckboxTarget.checked
  }

  slotInfoMode() {
    return this.hasSlotInfoLinksCheckboxTarget &&
      this.slotInfoLinksCheckboxTarget.checked
  }
}
