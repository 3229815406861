import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  editNote(e) {
    let button = $(e.srcElement)

    let card_form_container = button.closest('.card').find('.card-form-container')
    let card_text = button.closest('.card').find('.card-text')
    let note_text = card_form_container.find('#note_text')

    button.closest('.card').find('.update-note-button').show()
    button.hide()

    card_form_container.show()
    card_text.hide()
    
    note_text.summernote({airMode: true})
  }

  updateNote(event) {
    let button = $(event.srcElement)

    let card_form_container = button.closest('.card').find('.card-form-container')
    let card_form = event.srcElement.closest(".card").querySelector("form")
    let card_text = button.closest('.card').find('.card-text')
    let note_text = $(card_form).find('#note_text')
    
    card_form.requestSubmit()

    card_form_container.hide()
    card_text.html(note_text.summernote('code'))
    card_text.show()
    
    button.closest('.card').find('.edit-note-button').show()
    button.hide()

    note_text.summernote('destroy')
  }
}
