import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="global"
export default class extends Controller {
  static targets = ['addressSearchField', 'addressSearchForm']

  connect() {
    if (this.hasAddressSearchFieldTarget) {
      let controller = this

      $( this.addressSearchFieldTarget ).autocomplete({
	source: "/addresses/autocomplete_search.json",
	minLength: 2,
	create: function( event, ui ) {
          $('#found_address_link').click(
            function () {
              $('#address_select_form').submit();
              return false;
            });
	},
	focus: function( event, ui ) {
          controller.addressSearchFieldTarget.value = ui.item.location
          return false;
	},
	select: function( event, ui ) {
          window.location.href = `/addresses/${ui.item.id}`
          return false;
	}
      })
	.data( "ui-autocomplete" )._renderItem = function( ul, item ) {
          return $( "<li></li>" )
            .data( "item.autocomplete", item )
            .append( `<a>${item.location}</a>` )
            .appendTo( ul )
	}
    }
  }

  submitSearch(event) {
    this.addressSearchFormTarget.submit()
  }
}

