import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contacts"
export default class extends Controller {
  static targets = ['newNoteField']

  clearNewNote(event) {
    if (event.detail.success) {
      this.newNoteFieldTarget.value = ''
    }
  }
}
