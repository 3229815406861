import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['setDefaultsForm', 'conditionsForm', 'street1Field', 'notification']
  
  initialize() {
    this.enable_on_project_state()

    $('#new-note-text').summernote()

    $('[data-toggle="tooltip"]').tooltip()

    if(this.hasStreet1FieldTarget) {
      this.autoCompleteAddress()
    }
  }

  prepareProjectFilterParams(event) {
    event.detail[1].data = event.detail[1].data + '&' + $(this.conditionsFormTarget).serialize()
  }

  clearNewProjectForm() {
    $("#clear_form_button").hide()
    $('#new-project-form :input').attr('disabled', false)
    $('#new-project-form :text').val('')
    $("#new-project-form select :nth-child(1)").attr('selected', 'selected')
    $("#submit_row").show()
    $("#notification").hide('')
    $("#notification").html('')
    $('#notification').data('address-id', null)
  }

  autoCompleteAddress() {
    $(this.street1FieldTarget).autocomplete(
      {minChars: 2,}, {
	source: "/addresses/autocomplete_search.json",
	select( event, ui ) {
	  $(this.street1FieldTarget).unbind('blur')
	  
	  return $.getJSON(`/addresses/${ui.item.id}.json`, function(data) {
	    $('#notification').data('address-id', data.id)

            $('#project_address_street1').val(data.street1);
            $('#project_address_street2').val(data.street2);
            $('#existing_cities').val(`${data.city}`);
            $('#existing_states').val(`${data.state}`);
            $('#project_address_zip').val(data.zip);
            $('#existing_counties').val(`${data.county}`);
            $("#new-project-form").find(':input').each(function() {
              return $(this).attr('disabled', true);
            });
            $('#notification').html(`You selected an existing address. Please go to the <a href=\"/addresses/${data.id}\">address page</a> to add a new project.`)
            $('#notification').show()
	    
            $('#submit_row').hide()
            return $('#clear_form_button').show()
	  });
	}
      }
    ).data("ui-autocomplete")._renderItem = (ul, item) => $( "<li></li>" )
      .data( "item.autocomplete", item )
      .append( `<a>${item.location}</a>` )
      .appendTo( ul );

    $(this.street1FieldTarget).blur( (event) => {
      if (! $('#notification').data('address-id') ) {
	$.ajax({
	  type: "POST",
	  data: `street=${event.target.value}`,
	  url: "/addresses/clean_street.json",
	  success(data) {
            event.target.value = data.street
            $('#submit_button').removeAttr("disabled")
            $('#notification').show()
            $('#notification').html("The street address has been formatted.")
	  }	  
	})
      }
    })
  }
  
  enable_on_project_state() {
    let open = $('#project').data('open')

    this.set_overview_state(open)
    this.set_jobs_state(open)
  }

  set_overview_state(open) {
    $("#project_data_form, #details_form, #add_job_form").find(":input, :button").attr("disabled", ! open)
    $('#overview .btn').prop('disabled', ! open);
  }

  set_jobs_state(open) {
    $("#add_job_form").find(":input, :button").attr("disabled", ! open)
    
    $('#jobs .btn').each((index, element) => {
      if (! $(element).hasClass('always-enabled'))
	$(element).attr("disabled", ! open) 
    })

    $('#jobs .form-check-input').each((index, element) => {
      $(element).prop('disabled', $(element).data('disabled') || ! open)
    })
    $('#jobs .form-control').prop('disabled', ! open);
  }

  show_details_form(e) {
    $('#project_details').hide()
    $('#details_form').show()

    e.preventDefault()
  }

  show_project_data_form(e) {
    $('#project_data').hide()
    $('#project_data_form').show()

    e.preventDefault()
  }

  submitForm(e) {
    let form = $(e.srcElement).closest('form')
    
    Rails.fire(form.get(0), 'submit')
  }

  toggle_email_visibility(event) {
    $(event.target).data('active', $(event.target).data('active') !== undefined ? ! $(event.target).data('active') : false)
    
    $('#note_list .email-card').toggle($(event.target).data('active'))

    $('#toggle-emails').toggleClass('btn-primary', $(event.target).data('active'))
  }

  toggle_note_visibility(event) {  
    $(event.target).data('active', $(event.target).data('active') !== undefined ? ! $(event.target).data('active') : false)

    $('#note_list .note-card').toggle($(event.target).data('active'))

    $("#toggle-notes").toggleClass('btn-primary', $(event.target).data('active'))
  }
  
  // $('#project').data('open') is expected to be set
  // by the JS response.
  onToggleClosedStatus(e) {
    this.enable_on_project_state()
  }

  onProjectDetailsUpdate(e) {
    $('#project_details').show()
    $('#details_form').hide()
  }

  onProjectDataUpdate(e) {
    $('#project_data').show()
    $('#project_data_form').hide()
  }

  onAddNote(event) {
    if (event.detail.success) {
      $('#new-note-text').summernote('reset')
    }
  }

  onNewEventError(e) {
    let [data, status, xhr] = event.detail;
    this.neweventerrorsTarget.innerHTML = xhr.response
  }

  onEventUpdateSuccess(event) {
    let [data, status, xhr] = event.detail
    let modal = $(event.srcElement).closest('.event-container').find('.modal')

    $(event.srcElement).closest('.event-container').find('.modal').modal('hide')

    modal.on('hidden.bs.modal',  (e) => {
      $(event.srcElement).closest('.event-container').replaceWith(xhr.response)
    })

    modal.modal('hide')
  }
  
  onTaskableUpdateSuccess(event) {
    let [data, status, xhr] = event.detail
    
    $(event.srcElement).closest('.taskable-card').replaceWith(xhr.response)
  }
}
