import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    if ($('#address-card')) {
      let address = $('#address-card').data('address')
      
      // const geocoder = new (google.maps.Geocoder)
      
      // const myOptions = { 
      //   zoom: 15,
      //   mapTypeControl: false,
      //   zoomControlOptions: { style: google.maps.ZoomControlStyle.DEFAULT},
      //   mapTypeId: google.maps.MapTypeId.ROADMAP
      // };
      
      // geocoder.geocode({ 'address': address }, function(results, status) {
      //   if (status === google.maps.GeocoderStatus.OK) {
      //     const map = new (google.maps.Map)(document.getElementById('tiny-address-map'), myOptions);
      //     map.setCenter(results[0].geometry.location);
      //     const marker = new (google.maps.Marker)({
      //       map,
      //       position: results[0].geometry.location,
      //       title: address});
      //   } else {
      //     alert(`Geocode was not successful for the following reason: ${status}`);
      //   }
      // })
    }
  }
}
