import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reports"
export default class extends Controller {
  static targets = ['logConditionsForm', 'logClassCheckbox', 'dateRangeSelector', 'filterForm', 'startDate', 'endDate']

  deselectAllLogClasses(event) {
    for (let target of this.logClassCheckboxTargets) {
      target.checked = false
    }
  }

  handleDateRanges(event) {
    const selected_range = this.dateRangeSelectorTarget.value

    let start_date = null;
    let end_date = null;

    switch (selected_range) {
      case 'Last Week':
        start_date = moment().weekday(-7);
        end_date = moment(start_date);
        end_date.add(6, 'days');
        break;
      case 'Last Month':
        start_date = moment().startOf('month').subtract(1, 'month');
        end_date = moment(start_date);
        end_date.endOf('month');
        break;
      case 'This Week':
        start_date = moment().weekday(0);
        end_date = moment(start_date);
        end_date.add(6, 'days');
        break;
      case 'This Month':
        start_date = moment().startOf('month');
        end_date = moment(start_date);
        end_date.endOf('month');
        break;
      case 'Last Year':
        start_date = moment().startOf('year').subtract(1, 'year');
        end_date = moment(start_date);
        end_date.endOf('year');
        break;
      case 'This Year':
        start_date = moment().startOf('year');
        end_date = moment(start_date);
        end_date.endOf('year');
        break;
    }

    if (start_date && end_date) {
      this.startDateTarget.value = start_date.format('MM/DD/YYYY')
      this.endDateTarget.value = end_date.format('MM/DD/YYYY')

      this.filterFormTarget.requestSubmit()
    }
  }
}
