import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['filterForm', 'jobCostField', 'discountField', 'invoiceRequestAmount'] 

  addInvoiceButtonClicked(event) {
    $('#invoice-form').modal()
  }

  calculateInvoiceAmount() {
    let total = 0

    for (let target of this.jobCostFieldTargets) {
      let amount = parseFloat( target.value )

      if (! isNaN(amount))
	total += amount 
    }

    for (let target of this.discountFieldTargets) {
      let discount = parseFloat( target.value )

      if (! isNaN(discount))
	total -= discount 
    }

    return total
  }

  addCharge(event) {
    event.preventDefault()
    
    $.ajax({
      url: '/invoice_requests/prepare_charge',
      success: data => {
        $("#job-fields-list").append(data)

	this.updateInvoiceAmount()
      }
    })
  }

  removeCharge(event) {
    event.preventDefault()
    
    let row = event.target.parentElement.parentElement

    let job_id = row.dataset['job_id']
    let job_type_name = row.dataset['job-name']

    if (job_id && job_type_name) {
      $("#available-job-id").append(`<option value=\"${job_id}\">${job_type_name}</option>`);
    }

    // Create a destroy element using the name
    // of the amount field (arbitrary choice)
    const destroy_name = $(row).find('.job-cost-field').attr('name').replace('amount', '_destroy');
    $(row).append(`<input type=\"hidden\" name=\"${destroy_name}\" value=\"true\" />`);

    $(row).find(".title").wrap("<strike>");
    $(row).find(".remove-charge-button").hide();

    $(row).find(".job-cost-field").val('0.00');
    $(row).find(".job-cost-field").prop('disabled', true);

    $(row).find(".discount-field").val('0.00');
    $(row).find(".discount-field").prop('disabled', true);

    $(row).find(".note-field").prop('disabled', true);

    $('#add-job-button').show()

    this.updateInvoiceAmount()
  }
  
  addJob(event) {
    event.preventDefault()
    
    $.ajax({
      url: `/invoice_requests/prepare_charge?job_id=${$('#available_job_id').val()}`,
      success: data => {
        $("#job-fields-list").append(data)

        this.updateInvoiceAmount()
      }
    })
  }

  noChargeFieldChanged(event) {
    let row = $(event.srcElement).parent().parent()

    if ( $(event.srcElement).prop('checked') ) {
      row.find(".job-cost-field").val('0.00')
      row.find(".job-cost-field").prop('disabled', true)

      row.find(".discount-field").val('0.00')
      row.find(".discount-field").prop('disabled', true)
    } else {
      row.find(".job-cost-field").prop('disabled', false)
      row.find(".discount-field").prop('disabled', false)
    }
    
    this.updateInvoiceAmount()
  }

  updateInvoiceAmount() {
    this.invoiceRequestAmountTarget.value = this.calculateInvoiceAmount()
  }

  setFilterDefaults(event) {
    event.preventDefault()

    $.post({
      url: '/invoice_requests/set_defaults',
      data: {
        filter_form: $('#filter-form').serialize()
      }
    })
  }
  
  submitWithFilter(event) {
    for ( var pair of new FormData(this.filterFormTarget) ) {
      event.formData.append(pair[0], pair[1])
    }
  }
}
