// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import './add_jquery'
import './add_rails_ujs'

import 'jquery-ui/dist/jquery-ui'
import 'jquery.scrollto'

import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-multiselect/dist/js/bootstrap-multiselect'
import 'fullcalendar/dist/fullcalendar.js'

import './add_moment'
import 'tempusdominus-bootstrap-4'

import 'summernote'
import "trix"
import "@rails/actiontext"

import './add_fontawesome'

import MediumEditor from 'medium-editor/dist/js/medium-editor'

import "./controllers"

