import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'eventErrors', 'allDayFlag', 'dayModeButton', 'hourModeButton', 'startAtPicker', 'endAtPicker', 'startAtPickerInput', 'endAtPickerInput', 'newNoteField' ]

  refreshEvent(event) {
    let [data, status, xhr] = event.detail
    
    $(event.srcElement).closest('.event-container').html(xhr.response)

    this.newNoteFieldTarget.value = ''
  }
}
