import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['newContactName', 'relationsList']

  connect() {
    if (this.hasNewContactNameTarget) {
      this.newContactNameAutocomplete()
    }

    if (this.hasRelationsListTarget) {
      this.makeRelationsSortable()
    }
  }
  
  editContact(event) {
    let edit_button = $(event.srcElement)
    let update_button = edit_button.closest('.contact-relation-card').find('#update-contact-button')
    let card = edit_button.closest('.contact-relation-card')
    
    edit_button.hide()
    update_button.show()
    
    card.find('.show-contact-block').toggle()
    card.find('.edit-contact-relation').toggle()
  }
  
  updateContact(event) {    
    let update_button = $(event.srcElement)
    let card = update_button.closest('.contact-relation-card')
    let edit_button = card.find('#edit-contact-button')
    
    card.find('.update-contact-form').get(0).requestSubmit()

    this.makeRelationsSortable()
  }
  
  clearNewContactRelationForm() {
    $("#contact-relation-form #contact_relation_contact_attributes_id").val('')
    $("#contact-relation-form #existing_contact_id").val('')
    $('#contact-relation-form :input').each((index, element) => {
      if (element.type === 'text') {
    	$(element).val('')
      }
      
      $(element).attr("disabled", false)   
    })
    
    $("#contact-relation-form .same_as_select").val('')
    $("#contact-relation-form #contact_relation_contact_attributes_super_contact").prop('checked', false)
    
    this.contacterrorsTarget.innerHTML = ''
  }
  
  selectSameAs(event) {
    let id = $(event.target).closest('form').data('project-group-id');
    let label = $(event.target).val();
    let form = $(event.target).closest('form');
    
    if (label) {
      $.getJSON(`/contact_relations/get_contact_by_label/${id}/${label}.json`, function(data) {
	// clear form
	$('#contact-relation-form :input').each(function() {
	  if (this.type === 'text') { return $(this).val(''); }
	});
	
	//fill form
	form.find("#contact_relation_contact_attributes_id").val(data.contact.id);
	form.find("#new_contact_relation_name").val(`${data.contact.first_name} ${data.contact.last_name}`);
	form.find("#new_contact_relation_name").attr("disabled", true);
	
	return $.each(data.contact, function(k, v) {
	  form.find(`input[name=\"contact_relation[contact_attributes][${k}]\"]`).val(v);
	  return form.find(`input[name=\"contact_relation[contact_attributes][${k}]\"]`).attr("disabled", "disabled");
	});
      });
    } else {
      this.clearNewContactRelationForm()
    }
  }

  newEventCreated(event) {
    if (event.detail.success) {
      this.makeRelationsSortable()
    }
  }
  
  makeRelationsSortable() {
    $(this.relationsListTarget).sortable({
      // highlight the row on drop to indicate an update
      handle: $(".contact-handle"),
      items: $(".contact-relation-card"),
      stop(e, ui) {
	return ui.item.effect('highlight', {}, 1000);
      },
      update(e, ui) {
	const item_id = ui.item.data('item-id');
	const position = ui.item.index();
	
	return $.ajax({
	  type: 'post',
	  data: { contact_relation_type: { row_order: position } },
	  dataType: 'json',
	  complete(request) {
            return $('#checklist_item_types').effect('highlight');
	  },
	  url: `/contact_relations/${item_id}/update_row_order`
	})
      }
    })
  }
  
  onContactRelationUpdate(event) {
    let [data, status, xhr] = event.detail
    let card = $(event.srcElement).closest('.contact-relation-card')
    let id = card.attr('id')

    card.replaceWith(xhr.response)
    
    $(`#${id}`).effect('highlight', {}, 1000)
   }

  
  newContactNameAutocomplete() {
    $(this.newContactNameTarget).autocomplete({
      minlength: 3,
      source: "/contacts/autocomplete_search.js",
      select( event, ui ) {
    	return $.getJSON(`/contacts/${ui.item.id}.json`, function(data) {
    	  // clear form
    	  $('#contact-relation-form :input').each(function() {
    	    if (this.type === 'text') {
    	      if ($(this).attr('id') !== 'contact_relation_label') { return $(this).val(''); }
    	    }
    	  });

    	  // clear same as select
    	  $("#contact-relation-form .same_as_select").val('');

    	  //fill form
    	  $("#contact-relation-form").find("#contact_relation_contact_attributes_id").val(data.id);
    	  $("#contact-relation-form #existing_contact_id").val(data.id);
    	  $("#contact-relation-form").find("#new-contact-name").val(`${data.first_name} ${data.last_name}`);

    	  return $.each(data, function(k, v) {
    	    $("#contact-relation-form").find(`#contact_relation_contact_attributes_${k}`).val(v);
    	    return $("#contact-relation-form").find(`#contact_relation_contact_attributes_${k}`).attr("disabled", "disabled");
    	  });
    	});
      }
    }).data("ui-autocomplete")._renderItem = function( ul, item ) {
      let name;
      if (item.super_contact) {
    	name = `<b>${item.name}</b>`;
      } else {
    	({ name } = item);
      }

      return $( "<li></li>" )
    	.data( "item.autocomplete", item )
    	.append( `<a>${name}</a>` )
    	.appendTo( ul );
    };
  }
}
