import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle_available_slots_visibility(event) {
    $(event.srcElement).data('active', $(event.target).data('active') !== undefined ? ! $(event.target).data('active') : false)
    
    $(event.srcElement).closest('.taskable-card').find('.slot-list').toggle($(event.srcElement).data('active'))

    $(event.srcElement).toggleClass('btn-primary', $(event.srcElement).data('active'))
  }

  edit_taskable_description(e) {
    if ($('#project').data('open'))
      $(e.srcElement).closest('.taskable-card').find('.description').prop('contenteditable', true)
    
  }

  update_taskable_description(e) {
    if ($('#project').data('open')) {
      let block = $(e.srcElement).closest('.taskable-card').find('.description-block')
      let description = block.find('.editable-description')

      block.find('#taskable_description').val(description.html())
      
      Rails.fire(block.find('.taskable-description-form').get(0), 'submit')
      
      description.prop('contenteditable', false)
      
      description.effect('highlight', {}, 1000)
    }
  }

  disableButtonOnClick(event) {
    $(event.srcElement).prop('disabled', true)
  }

  toggle_display_completed_tasks(e) {
    $(e.srcElement).closest('.toggle-task-visibility').toggleClass('btn-primary btn-outline-primary')
    $(e.srcElement).closest('.card').find('.completed-task .task-body').toggle()
    
    e.preventDefault()
  }
}
