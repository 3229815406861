import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--staff-groups"
export default class extends Controller {
  static targets = ['staffList']

  connect() {
    if (this.hasStaffListTarget) {
      $(this.staffListTarget).multiselect({
	maxHeight: 600,
	numberDisplayed: 30})
    }
  }
}
