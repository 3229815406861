import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--task-types"
export default class extends Controller {
  connect() {
  }

  clearNewSlotFields() {
    $("#new_slot_day_select option[value='']").attr('selected', 'selected');
    $("#new_slot_start_at_select option[value='']").attr('selected', 'selected');
    $("#new_slot_end_at_select option[value='']").attr('selected', 'selected');
  }
}
