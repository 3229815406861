import { Controller } from "@hotwired/stimulus"
import MediumEditor from 'medium-editor/dist/js/medium-editor'

export default class extends Controller {
  connect() {
    let editor = new MediumEditor(this.element)

    $(this.element).bind('input propertychange', function() {
      if ($(this).attr("data-field-id")) {
	let form = $(this).closest('form');
	form.find("#" + $(this).attr("data-field-id")).val( $(this).html());
      }
    })
  }
}
